import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ActionEventType, actionEventAddListener, actionEventRemoveListener } from '../../common/utils/actionEvent.ts';
import { isSession } from '../../common/utils/session.ts';
import RouterApp from '../../routers/App/index.tsx';
import RouterNoSession from '../../routers/NoSession/index.tsx';
import Modal from '../../components/ui/Modal/index.tsx';
import Icon from '../../components/icon/index.tsx';
import Btn from '../../components/ui/Btn/index.tsx';

const Layout = ({ props }) => {

  const [session, setSession] = useState(isSession());
  
  const [automationIO, setAutomationIO] = useState(undefined);
  const [automationModalOpen, setAutomationModalOpen] = useState(false);
  

  useEffect(() => {
    const aeListener = actionEventAddListener((e, data) => {
      if (e === ActionEventType.AE_SESSION_STATE_CHANGED) {
        setSession(isSession());
      }
      if (e === ActionEventType.AE_IO_BUTTON_LONGPRESS) {
        setAutomationIO(data)
        setAutomationModalOpen(true);
      }
    });
    return () => {
      actionEventRemoveListener(aeListener);
    }
  }, [setSession]);

  return (
    <>
    <Modal
      isOpen={ automationModalOpen }
      setIsOpen={ v => setAutomationModalOpen(v) }
      title={ automationIO !== undefined ? `[ ${automationIO.id} ] Automation` : '' }
      body={ <>
        <Btn active={ true } icon='clock' text="Set Timer" />&nbsp;
        <Btn icon='clock' text="Delayed ON" />&nbsp;
        <Btn icon='clock' text="Delayed OFF" />&nbsp;
        <br />
        <br />
        Execute: <input type="number" style={{ width: 60 }} value={ 30 } /> minutes<br />
        <Btn active={ false } icon='clock' text="15 min" />&nbsp;
        <Btn active={ true } icon='clock' text="30 min" />&nbsp;
        <Btn active={ false } icon='clock' text="1 h" />&nbsp;
        <Btn active={ false } icon='clock' text="2 h" />&nbsp;
        <Btn active={ false } icon='clock' text="3 h" />&nbsp;
        <Btn active={ false } icon='clock' text="4 h" />&nbsp;
      </> }
    />
      <Routes>
        <Route path="*" element={ session ? <RouterApp /> : <RouterNoSession /> } />
      </Routes>
    </>
  );
};

export default Layout;
