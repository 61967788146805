const formatIO = (io: any, value: string): string => {
  
  let extension = '';

  if ('format' in io) {
    switch (io.format) {
      case 'temperature': extension = '°C'; break;
      case 'percent': extension = '%'; break;
    }
  }

  return value + extension;

};

export {
  formatIO,
}