import { apiCall, apiPOST } from "./apiCall.ts";
import { ioEvent } from "./ioEvent.ts";

const _ioValues: Array<any> = [];

const updateIOVal = (val: any) => {
  let io = _ioValues.find((io: any) => io.type === val.type && io.device_id === val.device_id && io.id === val.id);
  if (io === undefined) {
    _ioValues.push(val);
  } else {
    io = val;
  }
  ioEvent(val);
  // console.log(`UPD`, val);
};

interface dummyIOMessageProps {
  deviceId: string;
  i?: any;
  o?: any;
};

// TODO FOR TESTING / DEV PURPOSES ONLY
const dummyIOMessage = (params: dummyIOMessageProps) => {

  let msgId = Date.now();
  let postParams = { "id": msgId, "d": params.deviceId };
  if (params.i !== undefined) {
    postParams["i"] = params.i;
  }
  if (params.o !== undefined) {
    postParams["o"] = params.o;
  }

  apiPOST("message/add", postParams).then(r => {
    if (r.success) {
      apiPOST("message/ack?messageId=" + msgId + '&deviceId=' + params.deviceId).then(r => {
        setTimeout(() => {
          //refDevices();
        }, 100);
      });
    }
  });
};

// TODO FOR TESTING / DEV PURPOSES ONLY
const dummyReadIO = () => {
  apiCall('deviceios').then(r => {
    if (r.success && 'devices' in r) {
      if (Array.isArray(r.devices)) {
        r.devices.forEach((device: any) => {
          updateIOVal({ type: "device_io", device_id: device.deviceId, id: device.id, value: device.value });
        });
      }
    }
  });
};

dummyReadIO();
console.log('REINIT');

const dummyIOMessageIO = (io: any, value: string) => {
  //e.io, v[0].value === "1" ? "0" : "1"
  let iVal = {};
  iVal[io.id] = value;
  let params = {deviceId: io.device_id, i: iVal};
  
  dummyIOMessage(params);
}

/*
const sendIOTest = (io: any, value: string) => {
  console.log(io);
  console.log(value);

  let msgId = Date.now();
  let i = {};
  i[io.id] = value;

  apiPOST("message/add", { "id": msgId, "dev_id": io.device_id, "i": i }).then(r => {
    if (r.success) {
      apiPOST("message/ack?messageId=" + msgId).then(r => {
        dummyReadIO();
      });
    }
  });

};
*/

const ioValue = (io: any): string|undefined => {
  const ret = _ioValues.find((i: any) => i.type === io.type && i.device_id === io.device_id && i.id === io.id);
  console.log(`RET`, ret, io, _ioValues);
  //return ret;
  if (ret !== undefined) {
    return ret.value;
  }
}

export {
  dummyIOMessage,
  dummyIOMessageIO,
  dummyReadIO,
  ioValue,
};
