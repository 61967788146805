import React, { useEffect, useState } from "react";
import Pane from "../../components/ui/pane/index.tsx";
import Content from "../../components/ui/content/index.tsx";
import { developmentAPIEndpoint, setDevelopmentAPIEndpoint } from "../../common/utils/dev.ts";
import { isReactNative } from "../../common/utils/helpers.ts";

const PageDevelopmentSettings = () => {

  const [selectedEndpoint, setSelectedEndpoint] = useState('');

  const endpoints = [
    { name: 'localhost:8000', value :'' },
    { name: 'fusec.io', value: 'https://fusec.io/api/' },
  ]

  const epChanged = (val: string) => {
    alert(val);
    setSelectedEndpoint(val);
    setDevelopmentAPIEndpoint(val);
  };

  useEffect(() => {
    setSelectedEndpoint(developmentAPIEndpoint());
  }, []);

  return <>
    <Pane title="Development Settings" />
    <Content>
      Database Connection: <select onChange={ (e) => epChanged(e.target.value) }>
        { endpoints.map((e, index) => <option selected={ selectedEndpoint === e.value } key={ 'ep-' + index } value={ e.value }>{ e.name }</option>) }  
      </select>
      <br />
      <p>Web or Mobile? <strong><u>{ isReactNative() ? 'MOBILE' : 'WEB' }</u></strong></p>
    </Content>
  </>
};

export default PageDevelopmentSettings;
