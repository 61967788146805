import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PageDesktop from "../../pages/Desktop/index.tsx";
import LayoutApp from "../../layouts/AppLayout/index.tsx";
import PageTestIO from "../../pages/TestIO/index.tsx";
import PageDevelopmentSettings from "../../pages/DevelopmentSettings/index.tsx";
import PageTestFX from "../../pages/TestFX/index.tsx";
import PageDevices from "../../pages/Devices/index.tsx";
import PageDeviceSettings from "../../pages/Devices/settings.tsx";

const RouterApp = () => {

  const location = useLocation();

  return <LayoutApp>
    <Routes location={ location }>
      <Route path="/login" element={ <Navigate to="/" /> } />
      <Route path="/" element={ <PageDesktop /> } />
      <Route path="/test-io" element={ <PageTestIO /> } />
      <Route path="/test-fx" element={ <PageTestFX /> } />
      <Route path="/devices" element={ <PageDevices /> } />
      <Route path="/devices/:id" element={ <PageDeviceSettings /> } />
      <Route path="/dev-settings" element={ <PageDevelopmentSettings /> } />
      <Route element={ <Navigate to="/not-found" /> } />
    </Routes>
  </LayoutApp>

};

export default RouterApp;
