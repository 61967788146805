import React, { useEffect, useState } from "react";
import Content from "../../components/ui/content/index.tsx";
import Pane from "../../components/ui/pane/index.tsx";
import { useParams } from "react-router-dom";
import { apiCall } from "../../common/utils/apiCall.ts";
import Btn from "../../components/ui/Btn/index.tsx";

const PageDeviceSettings = () => {

  const { id } = useParams();

  const [device, setDevice] = useState<any>(undefined);

  useEffect(() => {
    apiCall('deviceio?deviceId=' + id).then(r => {
      if (r.success) {
        setDevice(r.devices)
      }
    });
  }, []);

  return <>
    <Pane title="Device Settings" />
    <Content>
      <p>Device: <strong><u>{id}</u></strong></p><br />
      { device !== undefined ? <table>
        { device.map((i: any) => i.type === "conf" ? <tr>
          <td>{ i.id }</td>          
          <td><select>
            <option>-</option>
          <option>NORVI-EX-R8</option>
          <option>NORVI-EX-R16</option>
          <option>OFF</option>
          <option>ON</option>
          <option>AUTO</option>
            </select></td>
        </tr> : '') }
      </table> : '' }
      <br />
      <Btn text="Save Changes" onClick={ () => window.alert('TODO') } />&nbsp;
      <Btn text="Cancel" goto={ '/devices' } />
    </Content>
  </>
}

export default PageDeviceSettings;
