import React, { useEffect, useState } from "react";
import { apiGET } from "../../common/utils/apiCall.ts";
import Content from "../../components/ui/content/index.tsx";
import Pane from "../../components/ui/pane/index.tsx";
import Btn from "../../components/ui/Btn/index.tsx";

const PageDevices = () => {

  const [devices, setDevices] = useState<Array<any>>([]);

  useEffect(() => {
    apiGET('devices').then(r => {
      if (r.success && 'devices' in r && Array.isArray(r.devices)) {
        setDevices(r.devices);
      }
    });
  }, []);

  return <>
    <Pane title="Devices" />
    <Content>
      { devices.map(d => <div style={{ marginBottom: 8, background: '#222', padding: 8, borderRadius: 8 }}>
        <p>ID: <strong>{ d.id }</strong></p>
        <p>Name: { d.name }</p>
        <p>Last Active: { d.last_active }</p>
        <p>Connection: <span style={{ color: '#00ff00' }}>CONNECTED</span></p>
        <p><Btn icon={ 'settings' } goto={ '/devices/' + encodeURIComponent(d.id) } /></p>
      </div>) }
    </Content>
  </>
}

export default PageDevices;
