let actionEventID = 0;
const actionEventListeners: any = [];

export enum ActionEventType {
  AE_SESSION_STATE_CHANGED,
  AE_IO_BUTTON_LONGPRESS,
};

export interface ActionEvent {
  event: ActionEventType;
  data?: any;
}

export interface IndexedActionEvent {
  id: number;
  func: any;
}

const actionEventRemoveListener = (id: number) => {
  if (actionEventListeners.length === 0) {
    console.log(`[ActionEvents] Notice: No listeners to remove.`);
  } else {
    for (let i=0; i<actionEventListeners.length; i++) {
      if (actionEventListeners[i].id === id) {
        actionEventListeners.splice(i, 1);
        console.log(`[ActionEvents] Listener (${id}) removed. Count: ${actionEventListeners.length}`);
        return true;
      }
    }
  }
  console.log(`[ActionEvents] Warning: Could not find added listener.`);
  return false;
};

const actionEventAddListener = (callback: any) => {
  actionEventListeners.push({ id: actionEventID, func: callback });
  actionEventID++;
  console.log(`[ActionEvents] Listener added. Count: ${actionEventListeners.length}`);
  return actionEventID-1;
};

const actionEvent = (actionEvent: ActionEvent) => {
  console.log(`[ActionEvent] *** EVENT: ${actionEvent.event} ***`);
  actionEventListeners.forEach((l: { func: (arg0: ActionEventType, arg1?: any) => void; }) => {
    l.func(actionEvent.event, actionEvent.data);
  });
};

export { 
  actionEventAddListener,
  actionEventRemoveListener, 
  actionEvent,
};
