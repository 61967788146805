import React from "react";
import Icon from "../../icon/index.tsx";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../ProgressBar/index.tsx";

interface BtnProps {
  text?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onLongPress?: () => void;
  goto?: string;
  progress?: number;
  progressLabel?: string;
}

const Btn:React.FC<BtnProps> = ({ text, icon, active, disabled, goto, onClick, onLongPress, progress, progressLabel }) => {

  const nav = useNavigate();

  let color = 'btn-blue';
  let classes: Array<any> = [];
  if (active) classes.push('active');
  
  classes.push(color);

  let timer: any = undefined;

  const onMouseDownHandler = () => {
    timer = setTimeout(() => {
      if (onLongPress !== undefined) {
        onLongPress();
      }
    }, 750);
  }

  const onMouseUpHandler = () => {
    clearTimeout(timer);
  }

  const onClickHandler = () => {
    if (onClick !== undefined) {
      onClick();
    }
    if (goto !== undefined) {
      nav(goto);
    }
  }

  return <button onMouseDown={ () => onMouseDownHandler() } onMouseLeave={ () => onMouseUpHandler() } onMouseUp={ () => onMouseUpHandler()} disabled={ disabled === true } onClick={ () => onClickHandler() } className={ classes.join(' ') }>
    { icon !== undefined ? <Icon i={ icon } /> : '' }
    { text !== undefined ? text : '' }
    { progress !== undefined ? <ProgressBar value={ 20 } label={ progressLabel } /> : '' }
  </button>
}

export default Btn;
