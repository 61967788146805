import { getItemFromStore, setItemToStore } from "./store.ts";

let _dev_api_endpoint = getItemFromStore('dev_api_endpoint', '');

const developmentAPIEndpoint = (): string => {
  return _dev_api_endpoint;
}

const setDevelopmentAPIEndpoint = (endpoint: string) => {
  _dev_api_endpoint = endpoint;
  setItemToStore('dev_api_endpoint', endpoint);
};

export {
  developmentAPIEndpoint,
  setDevelopmentAPIEndpoint,
}