import React from "react";
import LayoutNavigationMenu from "../NavigationMenu/index.tsx";

const LayoutApp = ({ children }) => {

  return <>
    <div className="al-top-menu-c" style={{ color: '#444', display: 'flex', alignItems: 'center', paddingLeft: '20px', fontStyle: 'italic', fontWeight: '700' }}>
      Fusec IOT
    </div>
    <div className="app-layout-container">
      <div className="al-nav-menu-c">
        <LayoutNavigationMenu />
      </div>
      <div className="al-content-c">
        { children }
      </div>
    </div>
  </>

};

export default LayoutApp;