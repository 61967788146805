import React from "react";
import './progressbar.scss';

interface ProgressBarProps { 
  value?: number;
  label?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  return <div className="mini-progress-bar-container">
  <div className="mini-progress-bar">
    <div className="mini-progress-bar-value" style={{ width: value + '%' }}></div>
  </div>
  <div className="mini-progress-bar-label">15:00</div>
</div>
};

export default ProgressBar;
