import React, { useEffect, useState } from "react";
import { apiCall, apiPOST } from "../../common/utils/apiCall.ts";
import Pane from "../../components/ui/pane/index.tsx";
import Content from "../../components/ui/content/index.tsx";
import { ioEvent } from "../../common/utils/ioEvent.ts";

const PageTestIO = () => {

  const [devices, setDevices] = useState<any>([]);

  const setIOState = (deviceId: string, io: string, state: string) => {

    let msgId = Date.now();
    let i = {};
    i[io] = state;

    apiPOST("message/add", { "id": msgId, "d": deviceId, "i": i }).then(r => {
      if (r.success) {
        apiPOST("message/ack?messageId=" + msgId + "&deviceId=" + encodeURIComponent(deviceId)).then(r => {
          setTimeout(() => {
            refDevices();
          }, 100);
        });
      }
    });
  };

  const refDevices = () => {
    apiCall('deviceio?deviceId=fusec.proto.iiot%2F0001').then(r => {
      if (r.success && 'devices' in r) {
        setDevices(r.devices);
        if (Array.isArray(r.devices)) {
          r.devices.forEach((device: any) => {
            ioEvent({ type: "device_io", device_id: device.deviceId, id: device.id, value: device.value });
          });
        }
      }
    });
  };

  useEffect(() => {
    refDevices();
  }, []);

  return <>
    <Pane title="TestIO" />
    <Content>
      <select><option>TestSegment</option></select>
      <br />
      <br />
      <div className="f f-gap f-wrap w-100">
        { devices.map((device: any) => <button className={ (device.value === "1" ? 'bg-yes' : device.value === "0" ? 'bg-no' : 'bg-gray') } key={ 'd-' + device.id } style={{ minWidth: 50 }} onClick={ () => setIOState(device.deviceId, device.id, (device.value === "1" ? "0" : "1")) }>
          { device.id }<br />{ device.value === null ? '?' : device.value }
        </button>) }
      </div>
    </Content>
  </>
};

export default PageTestIO;
