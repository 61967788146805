import { developmentAPIEndpoint } from "./dev.ts";

const isDevelopmentMode = () : boolean => {
  return process.env.NODE_ENV !== 'production';
};

const backendAPIURL = (): string => {
  const _dev = isDevelopmentMode();
  if (!_dev) {
    return 'https://fusec.io/api/';
  } else {
    const devAPI = developmentAPIEndpoint();
    if (devAPI !== undefined && devAPI !== null && devAPI !== "") {
      return devAPI;
    }
    return 'http://localhost:8080/api/';
  }
};

export {
  isDevelopmentMode,
  backendAPIURL,
}
