import React from "react";
import Pane from "../../components/ui/pane/index.tsx";
import Content from "../../components/ui/content/index.tsx";

const PageTestFX = () => {
  return <>
    <Pane title="Test FX" />
    <Content>
      Content
    </Content>
  </>
}

export default PageTestFX;
