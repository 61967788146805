import React, { useEffect } from "react";

import './modal.scss';
import Btn from "../Btn/index.tsx";

interface ModalProps {
  title?: any;
  body?: any;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

const Modal:React.FC<ModalProps> = ({ isOpen, setIsOpen, title, body }) => {

  const raiseSetIsOpen = (state: boolean) => {
    if (setIsOpen !== undefined) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
      const close = (e: any) => {
        if(e.keyCode === 27) {
          raiseSetIsOpen(false);
        }
      }
      window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [])

  return isOpen ? <div className="modal-container">
  <div className="modal">
    <div className="modal-title">{ title }</div>
    <div className="modal-body">{ body }</div>
    <div className="modal-buttons text-right"><Btn text="Close" onClick={ () => raiseSetIsOpen(false) } /></div>
  </div>
</div> : ''
}

export default Modal;
