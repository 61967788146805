import { BrowserRouter as Router } from 'react-router-dom';
import './style/layout.scss';
import './style/fusec.scss';
import './style/colors.scss';
import Layout from './layouts/Layout';
import initializeCustomViewRenderElements from './components/_cvr/_init.tsx';

initializeCustomViewRenderElements();

function App() {
  return (
    <>
      <Router basename="/">
        <Layout />
      </Router>
    </>
  );
}

export default App;
