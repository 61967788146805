import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "../../pages/Login/index.tsx";

const RouterNoSession = () => {

  const location = useLocation();

  return <Routes location={ location }>
      <Route path="/login" element={ <LoginPage /> } />
      <Route path="/" element={ <Navigate to="/login" /> } />
      <Route path="*" element={ <Navigate to="/login" /> } />
    </Routes>

};

export default RouterNoSession;
