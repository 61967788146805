import React, { useEffect, useState } from "react";
import Content from "../../components/ui/content/index.tsx";

import { CustomViewRenderer } from "../../common/components/CustomViewRenderer/index.tsx";
import jsonData from '../../test/views/desktop.json';
import { dummyIOMessage, dummyReadIO } from "../../common/utils/io.ts";
import Btn from "../../components/ui/Btn/index.tsx";
import Modal from "../../components/ui/Modal/index.tsx";

let ival: any = undefined;

const PageDesktop = () => {

  const [items, setItems] = useState<any>([]);
  const [dummyListener, setDummyListener] = useState(false);

  const startStop = () => {
    setDummyListener(!dummyListener);
  };

  const dummy = () => {
    dummyIOMessage({ deviceId: "fusec.proto.humitemp/0001", o: { "t": Math.round(Math.random() * 40), "h": Math.round(Math.random() * 99) } });
    dummyIOMessage({ deviceId: "fusec.proto.humitemp/0002", o: { "t": Math.round(Math.random() * 40), "h": Math.round(Math.random() * 99) } });
    dummyIOMessage({ deviceId: "fusec.proto.humitemp/0003", o: { "t": Math.round(Math.random() * 40), "h": Math.round(Math.random() * 99) } });
  }

  useEffect(() => {
    const json = JSON.parse(JSON.stringify(jsonData));
    console.log(json);
    setItems(json);
  }, []);

  useEffect(() => {

    if (dummyListener) {
      ival = setInterval(() => {
        dummyReadIO();
      }, 2000);
    } else {
      clearInterval(ival);
    }

  }, [dummyListener]);

  const cvrCallback = (data: any) => {
    console.log(data);
  };

  return <>
    <Content>
      <CustomViewRenderer data={ items } onCallback={ (data: any) => cvrCallback(data) } />
      <br />
      <Btn onClick={ () => startStop() } text="Dummy Msg Listener" active={ dummyListener } />&nbsp;
      <Btn onClick={ () => dummy() } text="Send Dummy HumiTemp Msg" />
    </Content>
  </>
};

export default PageDesktop;
