
let ioEventId = 0;
const ioEventListeners: any = [];

export interface IOEvent {
  type: string;
  device_id: string;
  id: string;
  value: string;
}

const ioEventRemoveListener = (id: number) => {
  if (ioEventListeners.length === 0) {
    //console.log(`[IOEvents] Notice: No listeners to remove.`);
  } else {
    for (let i=0; i<ioEventListeners.length; i++) {
      if (ioEventListeners[i].id === id) {
        ioEventListeners.splice(i, 1);
        //console.log(`[IOEvents] Listener (${id}) removed. Count: ${ioEventListeners.length}`);
        return true;
      }
    }
  }
  //console.log(`[IOEvents] Warning: Could not find added listener.`);
  return false;
};

const ioEventAddListener = (callback: any) => {
  ioEventListeners.push({ id: ioEventId, func: callback });
  const retId = ioEventId;
  ioEventId++;
  //console.log(`[IOEvents] Listener added. Count: ${ioEventListeners.length}`);
  return retId;
};

const ioEvent = (ioEvent: IOEvent) => {
  ioEventListeners.forEach((l: { func: (arg0: IOEvent) => void; }) => {
    l.func(ioEvent);
  });
};

export {
  ioEvent,
  ioEventAddListener,
  ioEventRemoveListener
};

