import React from "react";
import './cvr.scss';

let _elements: any = [];



const addCustomViewRenderElement = (type: string, render: any) => {
  _elements.push({ type: type, render: render });
};


interface PreferredRendererProps {
  item: any;
  children?: any;
  onCallback?: (data: any) => void;
};

const PreferredRenderer: React.FC<PreferredRendererProps> = ({ item, children, onCallback }) => {
  const i = _elements.find((e: any) => e.type === item.type);
  if (i === undefined) {
    const undef = _elements.find((e: any) => e.type === 'undefined');
    if (undef === undefined) {
      return <></>
    } else {
      return undef.render(undef);
    }
  } else {
    return i.render(item, children, onCallback);
  }
};

interface RenderProps {
  data: any;
  onCallback?: (data: any) => void;
};
const Render: React.FC<RenderProps> = ({ data, onCallback }) => {
  return data.map((i: any) => {
    return <>
      <PreferredRenderer item={ i } onCallback={ (data) => onCallback }>
        { 'children' in i && i.children.length > 0 ? <Render data={ i.children } /> : '[noChild]' }
      </PreferredRenderer>
    </>
  });
};


interface CustomViewRendererProps {
  data: any;
  onCallback?: (data: any) => void;
};

const CustomViewRenderer:React.FC<CustomViewRendererProps> = ({ data, onCallback }) => {
  return <Render data={ data } onCallback={ onCallback } />
};

export {
  CustomViewRenderer,
  addCustomViewRenderElement,
};
