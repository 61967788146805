import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../common/utils/session.ts";
import { isDevelopmentMode } from "../../common/utils/developmentMode.ts";
import Icon from "../../components/icon/index.tsx";

const LayoutNavigationMenu = () => {

  const nav = useNavigate();

  const goto = (whereTo: string) => {
    nav(whereTo);
  };

  return <div className="nav-menu">
    <ul>
      <li onClick={ () => goto('/') }><Icon  i="layout" />Desktop</li>
      <li onClick={ () => goto('/test-io') }>IO Test</li>
      <li onClick={ () => goto('/test-fx') }>FX Test</li>
      <li onClick={ () => goto('/devices') }>Devices</li>
      { isDevelopmentMode() ? <li onClick={ () => goto('/dev-settings') }>Dev Settings</li> : '' }
      <li onClick={ () => { if (window.confirm('u sure wanna logout?')) { logout() } } }>Logout</li>
    </ul>
  </div>;
};

export default LayoutNavigationMenu;
