import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import GaugeComponent from "react-gauge-component";
import { addCustomViewRenderElement } from "../../common/components/CustomViewRenderer/index.tsx";
import { formatIO } from "../../common/utils/formatter.ts";
import { dummyIOMessageIO } from "../../common/utils/io.ts";
import { IOEvent, ioEventAddListener, ioEventRemoveListener } from "../../common/utils/ioEvent.ts";
import Icon from "../icon/index.tsx";
import Btn from "../ui/Btn/index.tsx";
import { ActionEventType, actionEvent } from "../../common/utils/actionEvent.ts";

interface CVRElementProps {
  io?: any;
  children?: any;
  onIOStateChange?: (newState: any) => void;
};

const CVRElement: React.FC<CVRElementProps> = ({ io, children, onIOStateChange }) => {
  
  const _initIOs: any = [];
  //console.log(`io`, io);
  if (io !== undefined) {
    io.forEach((i: any) => {
      _initIOs.push( { "device_id": i.io.device_id, "id": i.io.id, "value": "n/a" } );
      console.log(`CVRE.Init`, i.io);
    });
  }

  console.log(`INIT IO STATES`, _initIOs);

  const [ios, setIOs] = useState<any>(_initIOs);

  const raiseIOStateChange = (state?: any) => {
    if (onIOStateChange !== undefined) {
      //console.log(`raise`, io);
      onIOStateChange(state === undefined ? ios : state);
    }
  };

  useEffect(() => {
    
    if (io !== undefined) {
      raiseIOStateChange(_initIOs);
    }

    const ioEventId = ioEventAddListener((event: IOEvent) => {
      
      const findItem = ios.find((item: any) => item.id === event.id && item.device_id === event.device_id);
      if (findItem !== undefined) {
        findItem.value = event.value;
        let newIos = [...ios];
        console.log(`FOUND`, newIos);
        raiseIOStateChange(newIos);
      }
      
      ///if (event.device_id === e.io.device_id && event.id === e.io.id) {
        //setValue(event.value);
        //const _f = ios.find((i: any) => i.device_id === event.device_id && i.id === event.id);
        //if (_f !== undefined) {
          //_f['value'] = event.value;
        //}
      //}
    });
    return () => {
      ioEventRemoveListener(ioEventId);
    }
  }, []);
  
  return <>
    { children }
  </>;
};

const initializeCustomViewRenderElements = () => {
  
  addCustomViewRenderElement('undefined', (e: any) => {
    return <>[{ e.type }]</>
  });

  addCustomViewRenderElement('button', (e: any) => {

    const [v, setV] = useState<any>([]);
    /* 
    <button onClick={ () => { sendIOTest(e.io, v[0].value === "1" ? "0" : "1") } } className={ 'fg-white button-control ' + (v[0].value === '1' ? 'bg-blue-active' : 'bg-blue') }>
        
        { 'icon' in e ? <Icon i={ e.icon } /> : '' }
        { e.text }
      </button>
    */

    return <CVRElement io={ [e] } onIOStateChange={ (vx) => { setV(vx) } }>
      { v === undefined || v.length === 0 ? '' : <Btn text={ e.text } icon={ 'icon' in e ? e.icon : undefined } active={ v[0].value === '1' } onLongPress={ () => actionEvent({ event: ActionEventType.AE_IO_BUTTON_LONGPRESS, data: { "id": e.io.id } }) } onClick={ () => { dummyIOMessageIO(e.io, v[0].value === "1" ? "0" : "1") } } />}
    </CVRElement>;
  });

  addCustomViewRenderElement('container', (e: any, children: any) => {
    return <CVRElement>
      <div className="cvr-container-title">{ 'title' in e ? e.title : 'Container' }</div>
      <div className="cvr-container">
        { children }
      </div>
    </CVRElement>
  });

  addCustomViewRenderElement('display', (e: any) => {

    const [v, setV] = useState<any>([]);

    return <CVRElement io={ e.display_items } onIOStateChange={ (vx) => setV(vx) }>
      <div className="cvr-display-container h-flex">
        <div className="cvr-display-v-buttons button-left-rounding-v v-flex">
          <button><Icon i={ '+' } /></button>
          <button><Icon i={ '-' } /></button>
        </div>      
        <div className="f-1 v-flex padded">
          <div className="cvr-display-title">{ e.title }</div>
          <div className="cvr-display-display-container h-flex">
            { 'display_items' in e ? e.display_items.map((displayItem: any) => {
              const dId: any = displayItem.io.device_id + '-' + displayItem.io.id;
              return <div title={ displayItem.io.id } className="f-1">
              { v === undefined || v.length === 0 ? '' : formatIO(displayItem.io, v.find((i: any) => i.id === displayItem.io.id && i.device_id === displayItem.io.device_id).value) }
            </div>
            }) : '' }
          </div>
        </div>
        <div className="cvr-display-v-buttons button-right-rounding-v v-flex">
          <button><Icon i={ 'power' } /></button>
          <button><Icon i={ 'bargraph' } /></button>
        </div>
      </div>
    </CVRElement>
  });

  addCustomViewRenderElement('chart', (e: any) => {

    type DailyStars = {
      date: Date,
      stars: number,
    }
    
    type Series = {
      label: string,
      data: DailyStars[]
    }
    
    const data: Series[] = [
      {
        label: 'React Charts',
        data: [
          {
            date: new Date(),
            stars: 202123,
          }
          // ...
        ]
      },
      {
        label: 'React Query',
        data: [
          {
            date: new Date(),
            stars: 10234230,
          }
        ]
      }
    ]

    const primaryAxis = React.useMemo(
      (): AxisOptions<DailyStars> => ({
        getValue: datum => datum.date,
      }),
      []
    )
  
    const secondaryAxes = React.useMemo(
      (): AxisOptions<DailyStars>[] => [
        {
          getValue: datum => datum.stars,
        },
      ],
      []
    )

    return <CVRElement>
      <div style={{ background: "#fff", height: 200, marginTop: 8, borderRadius: 8, minWidth: 200 }}>
        <Chart options={{ data, primaryAxis, secondaryAxes }} title={ 'Test Chart' } />
      </div>
    </CVRElement>
  });  

  addCustomViewRenderElement("gauge", (e: any) => {

    const [v, setV] = useState<any>([]);

    const formatLabel = (value: string): string => {
      return value + (e.io.format === 'temperature' ? '°C' : '%');
    }

    return <CVRElement io={ [e] } onIOStateChange={ vx => setV(vx) }>
      <div style={{ height: 200, maxHeight: 200, background: '#222' }}>
        <h5>{ e.title }</h5>
        <GaugeComponent
          style={{ height: 200, width: 300 }}
          value={ v === undefined || v.length === 0 ? 0 : v[0].value }
          labels={{ 
            valueLabel: { formatTextValue: value => formatLabel(value) },
            tickLabels: { defaultTickValueConfig: { formatTextValue: value => formatLabel(value) } },
          }}
         />
      </div>
    </CVRElement>
  });

  addCustomViewRenderElement("grid", (e: any, children: any) => {
    return <div className="h-flex f-wrap">
      { children }
    </div>
  });

  addCustomViewRenderElement("grid-item", (e: any, children: any) => {
    let size = 1;
    if ('size' in e) {
      size = e.size;
    }
    return <div className={ 'f-' + size }>
      { children }
    </div>
  });

};


export default initializeCustomViewRenderElements;
