import React from 'react';

interface SVGIconProps {
  src: string;
  size?: number;
};

const SVGIcon: React.FC<SVGIconProps> = ({ src, size }) => {
   const y_size = size === undefined ? 16 : size;
   const x_size = size === undefined ? 16 : size;
   return <img height={ y_size } width={ x_size } src={ src } alt="icon" />;
};

interface IconProps {
  i: string;
  size?: number;
};

const Icon: React.FC<IconProps> = ({ i, size }) => {
  let svg = '';
  switch (i) {
    case 'dashboard':
    case 'layout':
      svg = '/icons/dashboard.svg'; break;
    case 'temp':
    case 'temperature':
    case 'thermometer':
        svg = '/icons/thermometer.svg'; break;
    case 'power': svg = '/icons/power.svg'; break;
    case 'bargraph': svg = '/icons/bargraph.svg'; break;
    case '+': svg = '/icons/plus.svg'; break;
    case '-': svg = '/icons/minus.svg'; break;
    case 'car': svg = '/icons/car.svg'; break;
    case 'bulb': svg = '/icons/bulb.svg'; break;
    case 'settings': svg = '/icons/settings.svg'; break;
    case 'time': case 'clock': svg = '/icons/time.svg'; break;
    case 'star': svg = '/icons/star.svg'; break;
  }
  return <i className="svg-icon">
    { svg !== '' ? <SVGIcon size={ size } src={ svg } /> : '' }
  </i>
};

export default Icon;
