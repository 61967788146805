import { backendAPIURL } from "./developmentMode.ts";
import ApiCallResponse from "../interfaces/apiCallResponse.ts";
import apiCallRequestMethod from "../enums/apiCallRequestMethod.ts";
import { isSession, sessionToken } from "./session.ts";

const apiCall = async (operation: string, params:any = {}, requestMethod?: apiCallRequestMethod) => {
  return new Promise<ApiCallResponse>((resolve, reject) => {

    const dataParams:any = {
      method: requestMethod !== undefined ? requestMethod : 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    if (params.constructor === Object && Object.keys(params).length !== 0) {
      dataParams.body = JSON.stringify(params);
    }

    if (isSession()) {
      dataParams.headers['Auth-Token'] = sessionToken();
    }

    try {
      fetch(backendAPIURL() + operation, dataParams)
        .then(response => {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then(jsonRes => {
              if (response.status !== 200) {
                if ('error' in jsonRes) {
                  resolve({ success: false, error: jsonRes.error });
                } else {
                  resolve({ success: false, error: 'Unknown Error Occured, code: ' + response.status});
                }
              }
              if (jsonRes.success === false || 'success' in jsonRes === false) {
                console.log(`[apiCall] WARNING: Call ${operation} failed. Error: ${jsonRes.error}`);
              }
              resolve(jsonRes);
            });
          } else {
            if (response.status !== 200) {
              resolve({ success: false, error: 'Server responded with error code ' + response.status});
            }
            if ('blob' in params) {
              return response.blob().then(r => {
                resolve({ success: true, data: r });
              });
            } else {
              resolve({ success: false, error: 'Response is not JSON data.' });
            }
          }
        })
        .catch((e:any) => {
          resolve({ success: false, error: e.message });
        })

    } catch (e:any) {
      resolve({ success: false, error: e.message });
    }
  });
};

const apiDELETE = async (operation: string, params:any = {}) => {
  return apiCall(operation, params, apiCallRequestMethod.DELETE);
};

const apiPOST = async (operation: string, params:any = {}) => {
  return apiCall(operation, params, apiCallRequestMethod.POST);
};

const apiGET = async (operation: string) => {
  return apiCall(operation, {}, apiCallRequestMethod.GET);
};

export {
  apiCall,
  apiPOST,
  apiDELETE,
  apiGET,
};
