import React from "react";

interface PaneProps {
  title: string;
};

const Pane: React.FC<PaneProps> = ({ title }) => {
  return <div className="pane">
    { title }
  </div>
};

export default Pane;
