import React, { useState } from 'react';
import { login } from '../../common/utils/session.ts';
import './login.scss';

const LoginPage = () => {

  const [username, setUsername] = useState('testi');
  const [pass, setPass] = useState('');

  const loginClick = () => {
    login(username, pass);
  };

  return <>
    <div className="login-dialog">
      <strong>Fusec IOT Login</strong>
      <input placeholder="username" type="text" value={ username } onChange={ e => setUsername(e.target.value) } />
      <input placeholder="password" type="password" value={ pass } onChange={ e => setPass(e.target.value) } />
      <button onClick={ () => loginClick() }>Login</button>
    </div>
    <div className="text-center text-small">
      <br />
      <br />
      Copyright © 2024 Fusec Group. All Rights Reserved.
    </div>
  </>
};

export default LoginPage;
