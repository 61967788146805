import { ActionEventType, actionEvent } from "./actionEvent.ts";
import { apiPOST } from "./apiCall.ts";
import { getItemFromStore, setItemToStore } from "./store.ts";

let _isSession = getItemFromStore('isSession', false) === true;
let _token = getItemFromStore('token', '');

const isSession = (): boolean => {
  return _isSession;
}

const sessionToken = (): string => {
  return _token === undefined || _token === null ? '' : _token;
};

const login = (username: string, password: string): Promise<any> => {
  return new Promise<any>((resolve) => {
    apiPOST('auth/login', { username: username, password: password}).then(r => {
      if (r.success && 'token' in r) {
        setItemToStore('token', r.token);
        setItemToStore('isSession', true);
        _isSession = true;
        _token = r.token;
        actionEvent({ event: ActionEventType.AE_SESSION_STATE_CHANGED });
      } else {
        alert('LOGIN FAILED');
      }
      resolve(r);
    });
  });
};

const logout = (): Promise<any> => {
  return new Promise<any>((resolve) => {
    apiPOST('auth/logout');
    setItemToStore('token', '');
    setItemToStore('isSession', false);
    _isSession = false;
    _token = '';
    actionEvent({ event: ActionEventType.AE_SESSION_STATE_CHANGED });
    resolve({ success: true});
  });
};

export {
  isSession,
  sessionToken,
  login,
  logout,
};
